import styled from 'styled-components';

export const Wrapper = styled.div`
 
.ant-picker-dropdown .ant-picker-week-panel-row-selected td.ant-picker-cell:before{
  background: rgb(226, 106, 16) !important;
  color: #fff !important;
  .ant-picker-cell-inner{
    color: #fff;
  }
}
.ant-picker-dropdown .ant-picker-week-panel-row-hover td.ant-picker-cell:before {
  background-color: rgba(0,0,0,0.04) !important;
  color: #000000e0;
}
.ant-picker-dropdown .ant-picker-week-panel-row-hover td.ant-picker-cell .ant-picker-cell-inner{
  color: rgba(0, 0, 0, 0.88);
}

.ant-picker-dropdown .ant-picker-week-panel-row-selected.ant-picker-week-panel-row-hover td.ant-picker-cell::before{background: rgb(226, 106, 16) !important;
  color: #fff !important;
  .ant-picker-cell-inner{
    color: #fff;
  }
}
.ant-picker-dropdown .ant-picker-cell.ant-picker-cell-selected .ant-picker-cell-inner{
  background: rgb(226, 106, 16) !important;
  color: #fff !important;
}

.ant-picker-dropdown .ant-picker-panel-container {
    overflow: visible !important;
}
.ant-picker-cell-disabled {
  pointer-events: auto;
}
.ant-picker-dropdown .ant-picker-header-view button:hover{
  color: rgb(226, 106, 16);
}
.ant-picker-cell-disabled:hover .errortolltip {
  display: block;
}
.errortolltip {
  border-radius: 7px;
  background: var(--Color-3, #be1e2d);
  padding: 30px;
  position: absolute;
  bottom: -50px;
  left: 50px;
  z-index: 10;
  display: none;
  min-width: 300px;
}
.errortolltip p {
  color: var(--white--color);
  text-align: center;
  font-family: SF Pro Display;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.ant-picker-week-panel-row .ant-picker-cell-week {
  display: none;
}

.ant-picker-content th[aria-label="empty cell"] {
  display: none;
}

`